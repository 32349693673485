import React from 'react';
import { Svg } from './svg';

export const Logo = props => {
  return (
    <Svg {...props} display="inline-block">
      <rect width="24" height="24" rx="8" fill="#AAB3FF" />
      <path
        d="M7.67184 9.7863C8.11967 9.7863 8.4865 9.41736 8.4865 8.96696C8.4865 8.51656 8.11967 8.14282 7.67184 8.14282C7.22401 8.14282 6.85718 8.51656 6.85718 8.96696C6.85718 9.41736 7.22401 9.7863 7.67184 9.7863ZM10.1539 9.5611H16.5521C16.8809 9.5611 17.1429 9.29757 17.1429 8.96696C17.1429 8.63634 16.8856 8.37281 16.5521 8.37281H10.1539C9.82522 8.37281 9.56319 8.63155 9.56319 8.96696C9.56319 9.29757 9.82045 9.5611 10.1539 9.5611ZM7.67184 12.8193C8.11967 12.8193 8.4865 12.4504 8.4865 12C8.4865 11.5496 8.11967 11.1806 7.67184 11.1806C7.22401 11.1806 6.85718 11.5496 6.85718 12C6.85718 12.4504 7.22401 12.8193 7.67184 12.8193ZM10.1539 12.5941H16.5521C16.8809 12.5941 17.1429 12.3354 17.1429 12C17.1429 11.6694 16.8856 11.4058 16.5521 11.4058H10.1539C9.82522 11.4058 9.56319 11.6646 9.56319 12C9.56319 12.3306 9.82045 12.5941 10.1539 12.5941ZM7.67184 15.8571C8.11967 15.8571 8.4865 15.4882 8.4865 15.0378C8.4865 14.5874 8.11967 14.2136 7.67184 14.2136C7.22401 14.2136 6.85718 14.5874 6.85718 15.0378C6.85718 15.4882 7.22401 15.8571 7.67184 15.8571ZM10.1539 15.6319H16.5521C16.8809 15.6319 17.1429 15.3684 17.1429 15.0378C17.1429 14.7072 16.8856 14.4436 16.5521 14.4436H10.1539C9.82522 14.4436 9.56319 14.7024 9.56319 15.0378C9.56319 15.3684 9.82045 15.6319 10.1539 15.6319Z"
        fill="white"
      />
    </Svg>
  );
};
