import React from 'react';
import { Svg } from './svg';

export const ChainIcon = props => {
  return (
    <Svg {...props} display="inline-block" viewBox="0 0 40 40" width="40px" height="40px">
      <circle cx="20" cy="20" r="20" fill="#F2F2FF" />
      <path
        d="M19.2617 23.0312L20.5605 21.7393C19.9111 21.6641 19.3369 21.4111 18.9746 21.0488C17.8877 19.9551 17.8809 18.4375 18.9678 17.3506L21.3125 15.0127C22.3994 13.9258 23.917 13.9258 25.0039 15.0127C26.0977 16.1064 26.0977 17.624 25.0176 18.7041L24.1768 19.5381C24.4502 20.1602 24.5732 20.9736 24.3613 21.6709L26.2344 19.8115C27.998 18.0684 27.9912 15.5596 26.2207 13.7959C24.4502 12.0254 21.9619 12.0322 20.2051 13.7891L17.751 16.2432C15.9941 18 15.9941 20.4951 17.7578 22.2588C18.0996 22.6006 18.6328 22.9014 19.2617 23.0312ZM20.7314 17.0088L19.4326 18.3076C20.082 18.3828 20.6494 18.6357 21.0117 18.998C22.1055 20.0918 22.1055 21.6094 21.0254 22.6895L18.6738 25.0342C17.5869 26.1211 16.0693 26.1211 14.9893 25.0342C13.8887 23.9404 13.8887 22.4229 14.9756 21.3359L15.8096 20.502C15.543 19.8799 15.4199 19.0732 15.6318 18.3691L13.7588 20.2285C11.9951 21.9785 12.002 24.4805 13.7656 26.2441C15.5361 28.0146 18.0312 28.0078 19.7812 26.2578L22.2422 23.7969C23.9922 22.04 23.999 19.5449 22.2285 17.7812C21.8936 17.4395 21.3604 17.1455 20.7314 17.0088Z"
        fill="#3700FF"
      />
    </Svg>
  );
};
